import client from './client';
import { getUserTokenData } from '../utils/get-user-token-data';

export async function getFileBlob(url: string) {
    const token = getUserTokenData()?.token;

    return client.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
    });
}
