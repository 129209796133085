export default function getTimestamp(key: string) {
    if (typeof window === 'undefined') return null;

    const timestamp = localStorage.getItem(key);
    const isExpired = !timestamp || getIsTimestampExpired(timestamp);

    const setTimestamp = () => {
        localStorage.setItem(key, new Date().getTime().toString());
    };

    return { timestamp, isExpired, setTimestamp };
}

function getIsTimestampExpired(timestamp: string) {
    const NOW = new Date().getTime();
    const HOUR_IN_MILLISECONDS = 3600000;

    return parseFloat(timestamp) + HOUR_IN_MILLISECONDS < NOW;
}
