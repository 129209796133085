import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { FormikConfig } from 'formik';

import {
    wrapper,
    form,
    formFlex,
    sectionBox,
    rules,
    formSection,
    formContainer,
    rulesSection,
    formOverlay,
    active,
    subtitle,
    title,
    description,
    descriptionBottom,
    annotation,
    content as contentClass,
    loader,
    hasError,
    hasLoader,
    formLoading,
    modalDisabled,
} from './sign-up.module.scss';

import { getMediaByRelation } from '@alterpage/gatsby-plugin-image';
import {
    IInstagramRegisterResponse,
    IInstagramRegisterValues,
} from '../../models/instagram-register';
import { IErrorResponse } from '../../models/api-models.model';
import { ISection } from '../../models/section.model';
import { breakpoints } from '../../config/breakpoints';
import { relations } from '../../config/relations';
import { useModal } from '../../hooks/use-modal';
import { instagramRegisterForm } from '../../formik/instagram-register';
import { handleFormikErrors } from '../../utils/get-form-errors';
import { downloadBlob } from '../../utils/download-blob';
import getTimestamp from '../../utils/get-timestamp';
import { getFileBlob } from '../../api-clients/get-file-blob';
import {
    getInstagramRegisterRules,
    postInstagramRegister,
} from '../../api-clients/instagram-register';

import Section from '../hoc/section';
import FormGenerator from '../molecules/form-generator';
import useTranslations from '../../hooks/use-translations';
import Markdown from '../hoc/markdown';
import Loader from '../atoms/loader';
import { usePagePathname } from '../../hooks/use-page-pathname';
import { navigate } from 'gatsby';

export interface ISignUpProps {
    className?: string;
    TitleTag?: React.ElementType;
    section: ISection;
}

export type TSignUpLocationState = { isOrderRegistration?: boolean } | null;
const RULES_PADDING_BOTTOM = 64;

export default function SignUp({ className, section, TitleTag }: ISignUpProps) {
    const { sectionId, css, style } = section;
    const t = useTranslations('SignUp');
    const isMobile = typeof window !== 'undefined' && window.innerWidth < breakpoints.iPad;
    const timestamp = getTimestamp('popupTimestamp');
    const { data, isLoading, isSuccess, isError } = useQuery({
        queryKey: ['id'],
        queryFn: getInstagramRegisterRules,
    });
    const { addModal } = useModal();

    const formRef = useRef(null);
    const rulesRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const [isFormDisabled, setIsFormDisabled] = useState(!isMobile);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [isConsent1Checked, setIsConsent1Enabled] = useState(false);
    const [isConsent2Checked, setIsConsent2Checked] = useState(false);

    const { thankYou } = usePagePathname();

    const handleMutationError = (error: AxiosError<IErrorResponse>) => {
        setIsButtonLoading(false);
        handleFormikErrors(formRef.current, error, {
            network: t.error.network,
            global: t.error.global,
        });
    };

    const getPdf = async (response: IInstagramRegisterResponse) => {
        const filename = t.rules;

        try {
            const { data } = await getFileBlob(response.url);
            const pdfFileBlob = new Blob([data], { type: 'application/pdf' });
            downloadBlob(pdfFileBlob, filename);

            setTimeout(() => {
                if (typeof window !== 'undefined' && thankYou) {
                    navigate(thankYou);
                }
            }, 300);
        } catch {
            if (typeof window !== 'undefined' && thankYou) {
                navigate(thankYou);
            }
        }
    };

    const handleSuccess = (response: IInstagramRegisterResponse) => {
        getPdf(response);
    };

    const mutation = useMutation(postInstagramRegister, {
        onError: handleMutationError,
        onSuccess: handleSuccess,
    });

    const handleSubmit: FormikConfig<IInstagramRegisterValues>['onSubmit'] = (values) => {
        mutation.mutate({ ...values });
        setIsButtonLoading(true);
    };

    const handleAddModal = () => {
        addModal({
            modalKey: 'register-modal',
        });
    };

    useEffect(() => {
        if (timestamp?.isExpired && !isMobile && isLoading) {
            handleAddModal();
        }

        if (
            contentRef.current &&
            rulesRef.current &&
            contentRef.current.clientHeight === rulesRef.current.clientHeight - RULES_PADDING_BOTTOM
        ) {
            setIsFormDisabled(false);
        }

        rulesRef.current?.addEventListener('scroll', function () {
            const scrollHeight = rulesRef.current?.scrollHeight;

            if (scrollHeight && this.scrollTop + this.offsetHeight >= scrollHeight - 1) {
                setIsFormDisabled(false);
            }
        });
    }, [isLoading]);

    const handleFormClick = () => {
        if (isFormDisabled || !isConsent2Checked || !isConsent1Checked) {
            handleAddModal();
        }
    };

    return (
        <Section
            sectionId={sectionId}
            className={`${className} ${sectionBox}`}
            TitleTag={TitleTag}
            classes={{ container: wrapper }}
            css={css}
            style={style}
        >
            <div className={formSection}>
                <div className={contentClass}>
                    <p className={subtitle}>{t.subtitle}</p>
                    <Markdown className={title}>{t.title}</Markdown>
                    <Markdown className={description}>{t.description}</Markdown>
                    <Markdown className={`${description} ${descriptionBottom}`}>
                        {t.descriptionBottom}
                    </Markdown>
                    <div className={formContainer}>
                        <FormGenerator
                            className={`${form} ${isButtonLoading ? formLoading : ''}`}
                            formClassName={formFlex}
                            formikProps={{
                                onSubmit: handleSubmit,
                            }}
                            name="InstagramRegisterForm"
                            fields={instagramRegisterForm}
                            disabled={isFormDisabled}
                            formRef={formRef}
                            extraFieldsProps={{
                                rulesUrl:
                                    getMediaByRelation({
                                        media: data?.media,
                                        relation: relations.pdfDocument,
                                    })?.url || '',
                            }}
                            isLoading={isButtonLoading}
                            isConsent1Checked={isConsent1Checked}
                            isConsent2Checked={isConsent2Checked}
                            setIsConsent1Checked={setIsConsent1Enabled}
                            setIsConsent2Checked={setIsConsent2Checked}
                            isSignup={true}
                            descriptionFooter={t.footer}
                        />
                        <button
                            className={`${formOverlay} ${
                                isFormDisabled || !isConsent1Checked || !isConsent2Checked
                                    ? active
                                    : ''
                            } ${isMobile ? modalDisabled : ''}`}
                            onClick={handleFormClick}
                        />
                    </div>
                    <Markdown className={annotation}>{t.annotation}</Markdown>
                </div>
            </div>
            <div className={rulesSection}>
                <div
                    ref={rulesRef}
                    className={`${rules} ${isError ? hasError : ''} ${isLoading ? hasLoader : ''}`}
                >
                    {isLoading && <Loader className={loader} />}
                    {isSuccess && data && (
                        <div ref={contentRef}>
                            <Markdown>{data.content}</Markdown>
                        </div>
                    )}
                    {isError && <p>{t.error.rules}</p>}
                </div>
            </div>
        </Section>
    );
}
