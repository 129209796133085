import * as Yup from 'yup';

import { TFormFieldsFactoryProps } from '../models/form-field.model';

export const instagramRegisterForm = ({
    rulesUrl,
    t,
}: {
    rulesUrl: string;
} & TFormFieldsFactoryProps<'InstagramRegisterForm'>) => {
    return [
        {
            name: 'consent1',
            type: 'checkbox',
            label: t.consent1.label(rulesUrl),
            initialValue: false,
            schema: Yup.boolean().oneOf([true], t.consent1.required),
        },
        {
            name: 'consent2',
            type: 'checkbox',
            label: t.consent2.label(rulesUrl),
            initialValue: false,
            schema: Yup.boolean().oneOf([true], t.consent2.required),
        },
        {
            name: 'firstName',
            type: 'text',
            label: t.firstName.label,
            placeholder: t.firstName.placeholder,
            initialValue: '',
            schema: Yup.string().required(t.firstName.required),
        },
        {
            name: 'lastName',
            type: 'text',
            label: t.lastName.label,
            placeholder: t.lastName.placeholder,
            initialValue: '',
            schema: Yup.string().required(t.lastName.required),
        },
        {
            name: 'email',
            type: 'text',
            label: t.email.label,
            placeholder: t.email.placeholder,
            initialValue: '',
            schema: Yup.string().required(t.email.required),
        },
        {
            name: 'tiktokName',
            type: 'text',
            label: t.tiktokName.label,
            placeholder: t.tiktokName.placeholder,
            initialValue: '',
        },
        {
            name: 'name',
            type: 'text',
            label: t.instagramName.label,
            placeholder: t.instagramName.placeholder,
            initialValue: '',
            note: t.instagramName.note,
            schema: Yup.string().required(t.instagramName.required),
        },
        {
            name: 'followers',
            type: 'radio',
            options: [
                { label: t.followers.options.yes, value: true, enabled: true },
                { label: t.followers.options.no, value: false, enabled: true },
            ],
            label: t.followers.label,
            initialValue: false,
            schema: Yup.boolean().oneOf([true], t.followers.required),
        },
        {
            name: 'ofAge',
            type: 'radio',
            options: [
                { label: t.ofAge.options.yes, value: true, enabled: true },
                { label: t.ofAge.options.no, value: false, enabled: true },
            ],
            label: t.ofAge.label,
            initialValue: false,
            schema: Yup.boolean().oneOf([true], t.ofAge.required),
        },
        {
            name: 'shippingAddress',
            type: 'radio',
            options: [
                { label: t.shippingAddress.options.yes, value: true, enabled: true },
                { label: t.shippingAddress.options.no, value: false, enabled: true },
            ],
            label: t.shippingAddress.label,
            fullWidth: true,
            initialValue: false,
            schema: Yup.boolean().oneOf([true], t.shippingAddress.required),
        },
        {
            name: 'knowledge',
            type: 'radio',
            options: [
                {
                    label: t.knowledge.options.label,
                    value: 0,
                    enabled: true,
                },
                {
                    label: t.knowledge.options.label2,
                    value: 1,
                    enabled: true,
                },
            ],
            label: t.knowledge.label,
            fullWidth: true,
            column: true,
            initialValue: false,
            schema: Yup.number().typeError(t.knowledge.required).required(t.knowledge.required),
        },
        {
            name: 'description',
            type: 'textarea',
            label: t.description.label,
            fullWidth: true,
            placeholder: t.description.placeholder,
            initialValue: '',
            schema: Yup.string().required(t.description.required),
        },
        {
            name: 'submit',
            type: 'submit',
            label: t.submit,
        },
    ] as const;
};
