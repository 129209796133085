// extracted by mini-css-extract-plugin
export var active = "sign-up-module--active--d929e";
export var annotation = "sign-up-module--annotation--0c539";
export var content = "sign-up-module--content--a1f63";
export var description = "sign-up-module--description--699ae";
export var descriptionBottom = "sign-up-module--description-bottom--3bfb4";
export var form = "sign-up-module--form--4be6b";
export var formContainer = "sign-up-module--form-container--170c1";
export var formFlex = "sign-up-module--form-flex--889eb";
export var formLoading = "sign-up-module--form-loading--583ac";
export var formOverlay = "sign-up-module--form-overlay--09923";
export var formSection = "sign-up-module--form-section--97894";
export var hasError = "sign-up-module--has-error--a94c7";
export var hasLoader = "sign-up-module--has-loader--84662";
export var loader = "sign-up-module--loader--5986c";
export var modalDisabled = "sign-up-module--modal-disabled--bfe81";
export var rules = "sign-up-module--rules--45f19";
export var rulesSection = "sign-up-module--rules-section--ca2c5";
export var sectionBox = "sign-up-module--section-box--5670d";
export var subtitle = "sign-up-module--subtitle--5f2f0";
export var title = "sign-up-module--title--af955";
export var wrapper = "sign-up-module--wrapper--5f4a2";